@import "~@ui/styles/tools";
@import "../../../../styles/variables.scss";

@mixin medium-breakpoint {
    @media screen and (max-width: 1350px) {
        @content;
    }
}

.container {
    background: white;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: $color-title-lighter;
    font-size: 20px;
    height: 49px;
    transition: all 0.185s ease-in-out;
    font-weight: 600;
    width: fit-content;
    padding-left: 18px;
    padding-right: 32px;

    &:visited {
        color: $color-title-lighter;
    }

    &:hover {
        text-decoration: none;
        color: $color-title-lighter !important;
        background-color: $color-page-background;
    }

    &:active {
        background-color: $color-light-gray;
    }

    @include medium-breakpoint {
        padding-right: 24px;
    }

    @include media-tablet {
        font-size: 17px;
        width: 100%;
        max-width: 170px;
    }

    @include media-mobile {
        font-size: 14px;
    }
}

.icon {
    position: relative;
    width: 24px;
    height: 24px;
}