@import "@frontend/jetlend-web-ui/src/styles/tools";

.legal {
    margin-top: 36px;
    margin-bottom: 0;

    color: #fff;

    a, a:link, a:visited {
        color: $color-green;

        &:hover {
            color: $color-success;
        }

        &:active {
            color: $color-primary;
        }
    }

    @include media-tablet {
        text-align: center;
    }
}

.badges {
    display: flex;
    gap: 16px;

    @include media-tablet {
        justify-content: center;
    }
}

@include media-tablet-portrait {
    .badges {
        display: grid;
        grid-template-columns: 170px 170px;
        justify-content: center;
    }
}

@include media-mobile-xs {
    .badges {
        grid-template-columns: 1fr 1fr;
        border-top: none;
        justify-content: start;
        gap: 10px;
    }
}