@import "@frontend/jetlend-web-ui/src/styles/tools";

.button {
    display: inline-flex;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid rgba(255,255,255, 0.1);
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 49px;
    max-width: 170px;

    &>img {
        max-width: 170px;
    }

    &:hover {
        opacity: 0.8;
    }

    @include media-mobile-xs {
        display: flex;
        max-width: none;
        justify-content: center;
        text-align: center;

        img {
            max-width: none;
        }
    }
}