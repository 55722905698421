@import "@frontend/jetlend-web-ui/src/styles/tools";

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.content {
    color: #FFF;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    letter-spacing: 1px;

    @include media-laptop {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: inherit;
    }

    @include media-tablet {
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
    }
}

.badges {
    display: flex;
    gap: 24px;

    @include media-mobile-xs {
        flex-direction: column;
        gap: 10px;
    }
}

.badge {
    padding: 16px;
    background: rgba(#FFF, 0.15);
    border: 1px solid #EBEEEF;
    backdrop-filter: blur(12px);
    border-radius: 16px;
    color: #FFF;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;

    @include media-laptop {
        font-weight: 300;
        font-size: 22px;
        line-height: 28px;
    }

    @include media-tablet {
        font-size: 18px;
        line-height: 18px;
        font-weight: 300;
    }

    @include media-mobile {
        font-size: 16px;
        line-height: 16px;
        width: 100%;
    }
}