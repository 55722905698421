@import "~@ui/styles/tools";

.container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow-x: hidden;
    pointer-events: none;
}

.imageContainer {
    position: fixed;
    right: -67px;
    width: 250px;
    height: 261px;
    bottom: 20px;
    z-index: 1000;
    pointer-events: all;

    @include media-mobile {
        width: 182px;
        height: 191px;
        bottom: -25px;
    }
}

.image {
    height: 100%;
    width: 100%;
    display: block;
    pointer-events: all;
    z-index: 10000;
    position: relative;
}

.textContainer {
    position: absolute;
    border-radius: 12px;
    bottom: 60%;
    right: 77%;
    width: 60%;
    aspect-ratio: 2 / 1;
    z-index: 0;
}

.text {
    color: white;
    position: absolute;
    z-index: 100;
    top: 46%;
    left: 48%;
    transform: translate(-50%, -50%);
    width: fit-content;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-underline-offset: 3px;

    @include media-mobile {
        font-size: 12px;
        line-height: 16px;
    }
}

.hand-shaking {
    transform-origin: 50% 50%;
    animation: shaking 1s infinite alternate ease-in-out;

    @keyframes shaking {
        from {
            transform: rotate(-5deg);
        }

        to {
            transform: rotate(10deg);
        }
    }
}
