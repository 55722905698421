@import "@frontend/jetlend-web-ui/src/styles/tools";


.container {
    position: relative;
    z-index: 1;

    padding: 0 120px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    min-height: 100vh;

    @include media-tablet {
        padding: 0 60px;
    }

    @include media-mobile {
        padding: 0 30px;
    }
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    filter: brightness(0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    object-fit: cover;
}

.header {
    padding-top: 40px;
    padding-bottom: 20px;
    flex: 0.5;
}

.footer {
    padding-bottom: 60px;
}

.main {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 60px;
    padding-bottom: 40px;

    @include media-tablet {
        flex-direction: column-reverse;
        align-items: center;

        gap: 40px;
    }
}

.marketing {
    flex: 1;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    
    @include media-tablet {
        max-width: 446px;
    }
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    @include  media-desktop-md {
        gap: 12px;
    }

    @include media-tablet {
        align-content: center;
    }

    gap: 20px;
}

.background {
    position: absolute;
    object-fit: cover;
    pointer-events: none
}